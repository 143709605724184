:root {
  --primary-color: #00A99D;
  --secondary-color: rgb(169, 179, 178);
  --grey-color: #504a4a;
  --black-color: #000;
  --white-color: #fff;

  --xs: .5em;
  --sm: .8em;
  --lg: 1.2em;
  --md: 1.7em;
  --lg: 2.3em;
  --xl: 3em;
}

* {
  font-family: Arial, Helvetica, sans-serif;
}

.inactive {
  display: none;
}

/* TITULOS */

.h2 {
  font-weight: 700;
  font-size: 2.5em;
  color: var(--grey-color);
}

.dualColor {
  color: var(--primary-color);
}

.ul {
  padding: 0;
}

.li {
  list-style-type: none;
  text-align: left;
  margin-right: 0;
  cursor: pointer;
}

.li:hover {
  color: var(--primary-color);
}

/* Link move up hover */
.upLink {
  -webkit-transition: 1s linear;
  transition: .1s linear;
  border-radius: 0px;
  background: transparent;
  padding: 0 .1em;
}

.upLink:hover {
  margin-top: -5px;
  -webkit-box-shadow: 10px 10px 14px 2px rgba(0,0,0,0.47);
  box-shadow: 10px 10px 14px 2px rgba(0,0,0,0.47);
  border-radius: 10px;
  position: static;
}