@import url('../../bases/variables.css');

.proyects__container {
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
}

.proyects__card {
  width: 100%;
  height: auto;
  padding: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

