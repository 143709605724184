@import url('../../bases/variables.css');

.btn-scrollUp {
  position: fixed;
  bottom: 1.5em;
  right: 1.5em;
  padding: 1em 1.3em;
  background-color: var(--primary-color);
  color: var(--white-color);
  border: none;
  border-radius: 100%;
  outline: none;
  cursor: pointer;
}
.btn-scrollUp:focus {
  outline: none;
}


/* Small devices (landscape phones, 576px and up) */
@media (min-width: 356px) and (max-width: 767px) { 
  .btn-scrollUp {
    bottom: 5em;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) { 
  .btn-scrollUp {
    bottom: 2em;
  }
}