@import url('../../bases/variables.css');

.contact__container {
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
}

.contact__container img {
  width: 100%;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4em auto;
}

.container__inputs {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.container__inputs button {
  background-color: var(--white-color)!important;
  color: var(--primary-color)!important;
  border: 1px solid var(--primary-color)!important;
}

.container__inputs button:hover {
  background-color: var(--primary-color)!important;
  color: var(--white-color)!important;
  transform: scale(.95);
}


/* Small devices (landscape phones, 576px and up) */
@media (min-width: 356px) and (max-width: 767px) { 
  .container__inputs {
    width: 80%;
  }
}


/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) { 
  .container__inputs {
    width: 80%;
  }
}