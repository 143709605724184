@import url('../../bases/variables.css');


/* modulo 2 */

.container__info {
  height: 500px;
  margin-top: 4em;
  display: flex;
  align-items: center;
}

.container-img {
  width: 50%;
  height: 450px;
  text-align: end;
  padding-right: 1em;
}

.img1 {
  height: 100%;
}

.info-about {
  padding: 1em;
  display: flex;
  flex-direction: column;
  text-align: start;
}


/* modulo 3 */

.container__3 {
  margin-top: 4em;
  display: flex;
  justify-content: space-around;
}

.c3-info {
  width: 28%;
  height: auto;
  padding: 1em 2.5em;
}

.c3-icon {
  border-radius: 50%;
  padding: 1em;
  background-color: var(--primary-color);
  color: var(--white-color);
}

.c3-info h3 {
  margin: 1em 0;
}



/* Small devices (landscape phones, 576px and up) */
@media (min-width: 356px) and (max-width: 767px) { 
  .container__presentation {
    height: 200px;
    padding: 0 1em;
  }
  .container__info {
    height: auto;
    flex-direction: column;
  }
  .container-img {
    width: 100%;
    text-align: center;
    padding-right: 0;
  }
  .container__3 {
    flex-direction: column;
    align-items: center;
  }
  .c3-info {
    width: 100%;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
  .container__presentation {
    height: 200px;
    padding: 0 1em;
  }
  .container__info {
    height: auto;
    flex-direction: column;
    padding: 1em 2em;
  }
  .container-img {
    width: 100%;
    text-align: center;
    padding-right: 0;
  }
  .container__3 {
    flex-direction: column;
    align-items: center;
  }
  .c3-info {
    width: 100%;
    padding: 1em 6em;
  }
}

 /* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

 /* Extra large devices (large desktops, 1200px and up) */
/* @media (min-width: 1200px) {
} */